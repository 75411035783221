import React, { useEffect, useState } from "react"
import useDarkMode from "use-dark-mode"
import Toggle from "react-toggle"

const DarkMode = () => {
  const [isDarkMode, setDarkMode] = useState(false)
  const darkMode = useDarkMode(isDarkMode)
  useEffect(() => {
    if(darkMode.value !== isDarkMode) {
      setDarkMode(darkMode.value)
    }
  })
  return (
    <div className="flex items-center">
      <Toggle
        icons={false}
        checked={!isDarkMode}
        onChange={darkMode.toggle}
      />
    </div>
  )
}

export default DarkMode
