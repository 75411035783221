import { createGlobalStyle } from "styled-components"

import gamesIcon from "~/images/icon_games.png"
import eventIcon from "~/images/icon_event.png"
import downloadIcon from "~/images/icon_download.png"
import downloadWhite from "~/images/download_white.svg"

import allgamesIcon from "~/images/icon_allgames.png"
import slotIcon from "~/images/icon_slot.png"
import arcadeIcon from "~/images/icon_arcade.png"
import cardIcon from "~/images/icon_card.png"
import fishingIcon from "~/images/icon_fishing.png"
import boardIcon from "~/images/icon_board.png"
import searchIcon from "~/images/icon_search.png"
import sunIcon from "~/images/sun.svg"
import moonIcon from "~/images/moon.svg"
import playIcon from "~/images/multimedia-music-player-play-button.svg"

const GlobalStyle = createGlobalStyle`
  *:focus {
    outline: none !important;
  }

  body {
    background: ${props => props.bgPrimary};
    color: ${props => props.fontPrimary};

    &.dark-mode {
      background: ${props => props.bgPrimary};
      color: ${props => props.fontPrimary};

      a {
        color: #fff;
      }

      header {
        > div {
          background: ${props => props.bgPrimary}73;
        }
      }

      .support-wrapper {
        background: rgba(22, 22, 22, .45);

        .title {
          .close {
            &:before, &:after {
              background: #fff;
            }
          }
        }

        input {
          color: #161616;
        }
      }

      .filter-wrapper {
        background: rgba(22, 22, 22, .45);

        .title {
          .close {
            &:before, &:after {
              background: #fff;
            }
          }
        }

        input {
          color: #161616;
        }
      }

      .modal {
        background: rgba(22, 22, 22, .45);

        .title {
          .close {
            &:before, &:after {
              background: #fff;
            }
          }
          font-size: 30px;
        }

        input {
          color: #161616;
        }
      }

      .filter-event {
        background: #161616;
      }

      .round {
        label {
          background: #161616;
          border: .5px solid #fff;

          &.some {
            &:after {
              border-bottom: 1px solid #fff;
            }
          }
        }
      }

      .cate-bar {
        background: ${props => props.bgSecondary};
        /* color: ${props => props.fontPrimary}; */
      }

      .show-more {
        background: #222;
        border: 0.5px solid #6F6F6F;
        color: #fff;
      }

      .game-status {
        background: #222;
      }

      .card-content {
        background: ${props => props.bgSecondary};

        ul {
          color: #dfdfdf;
        }
      }

      .select-box {
        &:after {
          background: #fff;
        }

        span {
          border-top: 6px solid #fff;
        }

        .select-list {
          background: ${props => props.bgSecondary};
        }

        @media screen and (max-width: 1023px){
          span {
            border-top: 6px solid #5CC35F;
          }
        }
      }

      .search-input {
        background: #2c2c2c;
      }

      .close-icon {
        border-color: #fff;

        &:before {
          background: #fff;
        }

        &:after {
          background: #fff;
        }
      }

      .round-checkbox {
        margin-top: 12px;

        .text {
          padding-top: 5px;
        }

        .text-sub {
          padding-top: 5px;
          color: ${props => props.fontSecondary}
        }
      }
    }
  }

  img {
    width: 100%;
  }

  header {
    top: 0;

    > div {
      height: 60px;
      /* background: rgba(255, 255, 255, .45); */
      background: ${props => props.bgPrimary}73;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);

      a.nav-link {
        font-size: 16px;
        color: ${props => props.fontPrimary};

        &:not(:first-child) {
          margin-left: 28px;
        }
      }
    }
  }

  .cate-bar {
    height: 72px;
    background: ${props => props.bgSecondary};
    font-size: 12px;
    margin-top: 28px;
  }

  .iconMenu {
    margin-right: 6px;
    width: 28px;
    height: 18px;
    display: block;
  }

  .icon {
    margin-right: 6px;

    &.cate {
      margin-right: 9px;
    }

    &.games {
      background: url(${gamesIcon});
      width: 28px;
      height: 18px;
      display: block;
    }

    &.event {
      background: url(${eventIcon});
      width: 19px;
      height: 20px;
      display: block;
    }

    &.download {
      background: url(${downloadIcon});
      width: 20px;
      height: 20px;
      display: block;
    }

    &.downloadWhite {
      background: url(${downloadWhite});
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 !important;
      background-size: 30px 30px;
      background-repeat: no-repeat;
      transform: translate(6px, 3px);
    }

    &.allgames {
      background: url(${allgamesIcon});
      width: 24px;
      height: 20px;
      display: block;
    }

    &.slot {
      background: url(${slotIcon});
      width: 24px;
      height: 20px;
      display: block;
    }

    &.arcade {
      background: url(${arcadeIcon});
      width: 31px;
      height: 22px;
      display: block;
    }

    &.card {
      background: url(${cardIcon});
      width: 20px;
      height: 24px;
      display: block;
    }

    &.fishing {
      background: url(${fishingIcon});
      width: 27px;
      height: 28px;
      display: block;
    }

    &.board {
      background: url(${boardIcon});
      width: 23px;
      height: 25px;
      display: block;
    }

    &.search {
      background: url(${searchIcon});
      width: 25px;
      height: 25px;
      display: block;
    }

    &.playIcon {
      background: url(${playIcon});
      background-size: 20px 20px;
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .round-checkbox {
    margin-top: 12px;

    .text {
      padding-top: 5px;
    }

    .text-sub {
      color: ${props => props.fontSecondary}
    }
  }

  .round {
    label {
      background-color: #fff;
      border: .5px solid #161616;
      border-radius: 50%;
      cursor: pointer;
      width: 16px;
      height: 16px;
      left: 0;
      position: absolute;
      top: 0;

      &:after {
        border: 1px solid #000;
        border-top: none;
        border-right: none;
        content: "";
        height: 5px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transform: rotate(-45deg);
        width: 11px;
      }

      &.some {
        &:after {
          border-left: none;
          transform: rotate(0deg);
          opacity: 1;
        }
      }
    }

    input[type="checkbox"] {
      visibility: hidden;

      &:checked + label {
        /* background-color: #43ea7e; */
        background-color: ${props => props.bgThird};

        &.some {
          background-color: #fff;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .fade {
    animation-name: fade;
    animation-duration: 2.5s;
  }

  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }

  .fadeIn {
    animation-name: fade;
    animation-duration: 0.5s;
  }

  .fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }

  @keyframes fadeIn {
    from {opacity: .4}
    to {opacity: 1}
  }

  .react-toggle-track {
    &-check {
      background: url(${sunIcon}) no-repeat;
      background-size: 12px;
      width: 12px;
      height: 12px;
    }

    &-x {
      background: url(${moonIcon}) no-repeat;
      background-size: 12px;
      width: 12px;
      height: 12px;
    }
  }

  .select-box {
    span {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #202020;
      margin-left: 4px;
    }

    @media screen and (max-width: 1023px){
      span {
        border-top: 6px solid #5CC35F;
      }
    }

    .select-list {
      padding: 16px 22px;
      /* background: #fff; */
      background: ${props => props.bgSecondary};
      bottom: -25px;
      left: 50%;
      transform: translate(-50%, 100%);
      z-index: 1;
      min-width: 122px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;

      > div {
        &:not(:last-child) {
          margin-bottom: 8px;
          padding-bottom: 8px;
          border-bottom: 1px solid #c9c9c9;
        }
      }
    }

    @media screen and (min-width: 1023px) {
        &:after {
        content: "";
        position: absolute;
        background: #2d2d2d;
        width: 1px;
        height: 50px;
        top: 50%;
        right: -25px;
        transform: translateY(-50%);
        font-size: 12px;
      }
    }
  }

  .filter-event {
    background: #fff;
  }

  .close-icon {
    width: 18px;
    height: 18px;
    border: 1px solid #161616;
    border-radius: 50%;
    transform: rotate(45deg);
    cursor: pointer;
    margin-right: 18px;

    &:before {
      content: "";
      width: 14px;
      height: 1px;
      background: #161616;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: "";
      width: 1px;
      height: 14px;
      background: #161616;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .support-wrapper {
    width: 100%;
    padding: 40px;
    background: rgba(255,255,255,.45);
    backdrop-filter: blur(30px);
    font-size: 14px;

    .or {
      position: relative;

      &:before, &:after {
        content: "";
        background: #707070;
        width: 45%;
        height: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;

      .close {
        position: relative;
        cursor: pointer;
        transform: rotate(-45deg);
        width: 20px;
        height: 20px;

        &:before, &:after {
          content: "";
          background: #161616;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

        }

        &:before {
          width: 1px;
          height: 16px;
        }

        &:after {
          height: 1px;
          width: 16px;
        }
      }
    }

    @media screen and (max-width: 1023px) {
      height: 150vh;
      overflow-y: scroll;
      height: calc(100vh - 60px);
    }
  }

  .filter-wrapper {
    width: 120%;
    transition: .07s;
    height: 150vh;
    padding: 25px;
    background: rgba(255,255,255,.45);
    backdrop-filter: blur(30px);
    font-size: 14px;
    overflow-y: scroll;
    height: calc(100vh - 60px);

    .or {
      position: relative;

      &:before, &:after {
        content: "";
        background: #707070;
        width: 45%;
        height: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;

      .close {
        position: relative;
        cursor: pointer;
        transform: rotate(-45deg);
        width: 20px;
        height: 20px;

        &:before, &:after {
          content: "";
          background: #161616;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

        }

        &:before {
          width: 1px;
          height: 16px;
        }

        &:after {
          height: 1px;
          width: 16px;
        }
      }
    }
  }

  .modal {
    padding: 50px;
    background: rgba(255,255,255,.45);
    backdrop-filter: blur(40px);
    bottom: 30%;
    left: 36%;
    width: 500px;

    .title {
      font-size: 30px;
      font-weight: bold;
    }

    .button {
      width: 80%;
    }

    .close {
      position: relative;
      cursor: pointer;
      transform: rotate(-45deg);
      width: 20px;
      height: 20px;

      &:before, &:after {
        content: "";
        background: #161616;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

      }

      &:before {
        width: 1px;
        height: 16px;
      }

      &:after {
        height: 1px;
        width: 16px;
      }
    }

    @media screen and (max-width: 1024px) {
      left: 25%;
    }

    @media screen and (max-width: 770px) {
      left: 17%;
    }

    @media screen and (max-width: 700px) {
      left: 10%;
      padding: 20px;
      width: 400px;
      bottom: 40%;

      .button {
      width: 40%;
      margin: 5px;
      }
    }

    @media screen and (max-width: 490px) {
      left: 2%;
      width: 380px;
    }

    @media screen and (max-width: 420px) {
      left: 8%;
      width: 300px;
    }

    @media screen and (max-width: 350px) {
      left: 0%;
      width: 290px;

      .button {
      width: 35%;
      }
    }

    @media screen and (max-width: 285px) {
      width: 280px;
      margin-left: 8px;
    }
  }
`

export default GlobalStyle
