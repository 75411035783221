import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import Snowfall from 'react-snowfall'

import Header from "~/components/header"
import Support from "~/components/support"
import Footer from "~/components/footer"
import PlayUrlContext from "~/context/play-url"

import GlobalStyle from "~/styles/js/global"
import useDarkMode from "use-dark-mode"

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      },
      theme: allMarkdownRemark(filter: {collection: {eq: "theme"}}) {
        edges {
          node {
            file
            frontmatter {
              light_theme {
                background_color {
                  primary
                  secondary
                  third
                  fourth
                }
                border_color {
                  primary
                  secondary
                  third
                  fourth
                }
                font_color {
                  primary
                  secondary
                  third
                }
              }
              dark_theme {
                background_color {
                  primary
                  secondary
                  third
                  fourth
                }
                border_color {
                  primary
                  secondary
                  third
                  fourth
                }
                font_color {
                  primary
                  secondary
                  third
                }
              }
            }
          }
        }
      }

      iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
        edges {
          node {
            id
            frontmatter {
              question
              sort
              sun
              gift
              play
            }
          }
        }
      }
    }
  `)
  const theme = data.theme.edges[0].node.frontmatter;
  const iconWeb = data.iconWeb.edges[0].node.frontmatter;
  const url = pageContext.slug
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/')
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
    .map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }))

  const [toggleSupport, setToggleSupport] = useState(false)
  const { value } = useDarkMode()

  const handleSupport = (val) => {
    setToggleSupport(val)
  }
  return (
    <PlayUrlContext.Consumer>
      {({ playUrl }) => (
        <div className="relative">
          <GlobalStyle
            bgPrimary={value ? theme.dark_theme.background_color.primary : theme.light_theme.background_color.primary}
            bgSecondary={value ? theme.dark_theme.background_color.secondary : theme.light_theme.background_color.secondary}
            bgThird={theme.light_theme.background_color.third}
            fontPrimary={value ? theme.dark_theme.font_color.primary : theme.light_theme.font_color.primary}
            fontSecondary={value ? theme.dark_theme.font_color.secondary : theme.light_theme.font_color.secondary}
          />
          <Snowfall />
          <Header
            siteName={`Strapi`}
            playUrl={playUrl}
            langKey={pageContext.langKey}
            langsMenu={langsMenu}
            handleSupport={handleSupport}
            theme={theme}
            iconWeb={iconWeb}
          />
          {toggleSupport && <Support
            langKey={pageContext.langKey}
            handleSupport={handleSupport}
          />}
          <div
            className="flex flex-col min-h-screen m-auto"
            style={{ marginTop: "60px" }}
          >
            <main className="flex-1">{children}</main>
          </div>
          <Footer
            themeColor={theme}
            playUrl={playUrl}
            langKey={pageContext.langKey} />
        </div>
      )}
    </PlayUrlContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
